@import "../../../../assets/theme/_var.scss";

.error_msg {
    font-size: 1.4rem;
    color: $errorclr;
    text-align: left;

    @media (max-width: 479px) and (max-width: 1439px) {
        font-size: 1.2rem;
    }

    @media (max-height: 419px) and (max-width: 1439px) {
        font-size: 1rem;
    }
}