@import "../../../assets/theme/_var.scss";

.back_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 9rem;
    max-width: 177.2rem;
    padding-inline: 4rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;

    @media (max-width: 575px) {
        padding-inline: 3rem;
    }

    @media (max-height: 991px) and (max-width: 1439px) {
        top: 5rem;
    }

    @media (max-width: 991px) and (max-height: 479px) {
        top: 3rem;
    }

    @media (max-height: 419px) and (max-width: 991px) {
        top: 2rem;
        padding-inline: 3rem;
    }


    .back_btn {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.8rem;
        border: none;
        outline: none;
        padding: 0;
        background-color: transparent;
        letter-spacing: 0.01em;
        text-align: center;
        color: $baseclr;

        img {
            margin-right: 0.3rem;
            transition: 200ms ease-in;
        }

        @media (max-height: 619px) and (max-width: 1199px) {
            font-size: 1.4rem;
        }

        @media (max-height: 419px) and (max-width: 991px) {
            font-size: 1.2rem;
        }

        &:hover {
            img {
                transform: translateX(-0.4rem);
            }
        }
    }

    .donate_btn {
        font-size: 1.6rem;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        color: $baseclr;
        font-weight: 400;
        line-height: 1.8rem;
        letter-spacing: 0.01em;

        @media (max-height: 619px) {
            font-size: 1.2rem;

            svg {
                max-width: 1.8rem;
            }
        }

        svg {
            margin-right: 0.7rem;
        }
    }
}