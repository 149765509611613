@import "./assets/theme/_var.scss";

html {
    font-size: 62.5%;
}

body {
    font-family: $basefont;
    background-color: $black23;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}

img,
svg,
video,
canvas {
    max-width: 100%;
}

a,
span {
    text-decoration: none;
    display: inline-block;
}

.container {
    max-width: 151.6rem;
    padding-inline: 2rem;

    @media (max-width: 1599px) {
        max-width: 134rem;
    }

    @media (max-width: 1439px) {
        max-width: 114rem;
    }

    @media (max-width: 1199px) {
        max-width: 76rem;
    }

    @media (max-width: 767px) {
        max-width: 64rem;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 0s;
    background-color: $black !important;
    caret-color: $white;
    -webkit-text-fill-color: $white;
    -webkit-text-fill-color: $white;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active,
input:-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 0s;
    background-color: $black !important;
    caret-color: $white;
    -moz-text-fill-color: $white;
    -webkit-text-fill-color: $white;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}


ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.table {
    --bs-table-bg: transparent;
}

.slick-slider {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.slick-list.draggable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// .Toastify__toast-container {
//     width: 100vw;

//     .Toastify__toast-theme--light {
//         background-color: transparent;
//         padding: 0;
//         justify-content: center;
//     }
// }

.Toastify {
    font-size: 1.6rem;
}

.clickable {
    cursor: pointer;
}


// modal

.common_modal {
    .modal-dialog {
        padding: 1.2rem;
        max-width: 52.9rem;
        margin: auto;
        min-height: 100vh;

        .modal-content {
            border: 2px solid $baseclr2;
            background-color: $black23;
            box-shadow: 0px 0px 40px 0px rgba($baseclr2, 0.302);

            .modal-header {
                border-bottom: 0;
                padding: 1.7rem 1.7rem 3.2rem 1.7rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    font-size: 1.8rem;
                    color: $baseclr2;

                    @media (max-width: 1439px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 1.4rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }

                @media (max-height: 479px) and (max-width: 1199px) {
                    padding: 1rem 1.5rem;
                }

                @media (max-width: 575px) {
                    padding: 1rem;
                }
            }

            .modal-body {
                padding: 0 6rem 6.4rem;

                @media (max-width: 1439px) {
                    padding: 0 4rem 4rem;
                }

                @media (max-width: 1199px) {
                    padding: 0 3rem 3rem;
                }

                @media (max-height: 619px) or (max-width: 991px) {
                    padding: 0 2rem 2rem;
                }

                @media (max-width: 575px) {
                    padding: 0 1rem 1rem;
                }
            }
        }
    }
}