@import "../../../assets/theme/_var.scss";

.terms_services {
    padding: 4rem 0;
    position: relative;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        background-color: rgba(black, 0.388);
        top: 0;
        left: 0;
    }

    .heading {
        margin-bottom: 4rem;
        padding-inline: 10rem;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        @media (max-width: 1599px) {
            margin-bottom: 3.4rem;
        }

        @media (max-width: 1479px) {
            margin-bottom: 2.4rem;
        }

        @media (max-width: 1199px) {
            margin-bottom: 1.8rem;
        }

        .bckbtn {
            width: fit-content;
            left: 0;
            transform: translateY(-50%);
            top: 50%;
            padding: 0;
        }
    }

    ul {
        li {
            margin-bottom: 3rem;
            color: white;

            @media (max-width: 1599px) {
                margin-bottom: 2.4rem;
            }

            @media (max-width: 1479px) {
                margin-bottom: 1.8rem;
            }

            @media (max-width: 1199px) {
                margin-bottom: 1.4rem;
            }

            &:first-child {
                margin-bottom: 1rem;

                @media (max-width: 1199px) {
                    margin-bottom: 0.6rem;
                }
            }

            small {
                font-size: 1.4rem;
                color: white;
            }

            p {
                font-size: 1.8rem;
                color: $white;
                font-family: $secondaryfont;

                @media (max-width: 1599px) {
                    font-size: 1.6rem;
                }

                @media (max-width: 1479px) {
                    font-size: 1.4rem;
                }

                @media (max-width: 1199px) {
                    font-size: 1.2rem;
                }

                a {
                    color: $yellowclr;
                    text-decoration: underline;

                    &:hover {
                        color: $yellowclr2;
                    }
                }
            }
        }
    }

    ol {
        li {
            color: white;

            &::marker {
                font-size: 1.6rem;


                @media (max-width: 1479px) {
                    font-size: 1.4rem;
                }

                @media (max-width: 1199px) {
                    font-size: 1.2rem;
                }
            }
        }
    }
}