@import "../../../assets/theme/_var.scss";

.status_page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;

    .heading {
        max-width: 82rem;
        margin: 0 auto 8.5rem;

        @media (max-width: 1479px) {
            margin: 0 auto 5rem;
            padding: 0 60px;
        }

        @media (max-width: 1199px) and (max-height: 419px) {
            margin: 0 auto 3rem;
        }
    }

    .box {
        border: 2px solid transparent;
        background: linear-gradient(180deg, #000000 0%, rgba(18, 5, 1, 1) 44.73%) padding-box,
            linear-gradient(180deg, #FFBA55 1.32%, rgba(255, 186, 85, 0.19) 48.44%, #FFBA55 93.51%) border-box;
        position: relative;
        max-width: 67rem;
        margin: 0 auto;
        padding: 9.6rem 3.6rem 5.7rem;

        @media (max-width: 1479px) {
            padding: 4rem 2rem;
        }

        @media (max-width: 1199px) and (max-height: 419px) {
            padding: 3rem 1.8rem;
        }

        @media (max-width: 1199px) and (max-height: 379px) {
            padding: 2rem 1.8rem;
        }

        &::before {
            content: "";
            width: 100%;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -0.2rem);
            height: 0.2rem;
            max-width: 29rem;
            background-color: $black;

            @media (max-width: 1479px) {
                max-width: 20rem;
            }

            @media (max-width: 1199px) and (max-height: 619px) {
                max-width: 14rem;
            }
        }

        .bow {
            position: absolute;
            top: 0;
            max-width: 29rem;
            left: 50%;
            transform: translate(-50%, -4.4rem);

            @media (max-width: 1479px) {
                max-width: 20rem;
                transform: translate(-50%, -3.2rem);
            }

            @media (max-width: 1199px) and (max-height: 619px) {
                max-width: 14rem;
                transform: translate(-50%, -2.2rem);
            }
        }

        .logo {
            max-width: 28.8rem;
            display: block;
            margin: 0 auto 3.6rem;

            @media (max-width: 1479px) {
                max-width: 20rem;

            }

            @media (max-width: 1199px) and (max-height: 619px) {
                max-width: 14rem;
                margin: 0 auto 2rem;
            }

            @media (max-width: 1199px) and (max-height: 419px) {
                max-width: 10rem;
                margin: 0 auto 1rem;
            }

            @media (max-width: 1199px) and (max-height: 379px) {
                max-width: 8rem;
            }

        }

        ul {
            li {
                background-color: rgba($black, 0.6);
                height: 6rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 4rem;

                @media (max-width: 1199px) and (max-height: 419px) {
                    height: 4.6rem;
                }

                >* {
                    &:first-child {
                        padding-right: 0.8rem;
                    }

                    &:last-child {
                        padding-left: 0.8rem;
                    }
                }

                h4 {
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 2.2rem;
                    color: rgba($white, 0.6);

                    @media (max-width: 1199px) and (max-height: 419px) {
                        font-size: 1.4rem;
                        line-height: 1.4;
                    }
                }

                p {
                    color: $baseclr;
                    text-align: right;
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 2.2rem;

                    @media (max-width: 1199px) and (max-height: 419px) {
                        line-height: 1.4;
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}