@import "../../../../assets/theme/_var.scss";

body {
    :global {
        .portrait_modal_backdrop {
            &.show {
                opacity: 1;
                background-color: rgba($black , 0.4);
                backdrop-filter: blur(2rem);
            }
        }
    }
}

.portrait_modal {
    :global {
        .modal-dialog {
            padding: 1.2rem;

            .modal-content {
                background-color: $black23;
                box-shadow: 0px 0px 40px 0px rgba($baseclr2, 0.302);
                text-align: center;

                .modal-body {
                    padding: 8rem 4rem;
                }
            }
        }
    }

    :global {
        @keyframes rotate {
            from {
                transform: rotate(0);
            }

            to {
                transform: rotate(45deg);
            }
        }
    }

    .mobile_icon {
        animation: rotate 1s ease-in-out infinite;
        scale: 0.3;
        margin-bottom: -3rem;

        svg {

            path {
                fill: $baseclr;
            }
        }
    }

    p {
        font-size: 2.4rem;
        color: $baseclr;
    }
}