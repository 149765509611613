@import "../../../../assets/theme/_var.scss";

.meaning {
    :global {
        .modal-dialog {
            max-width: 52rem;
            margin: auto;
            padding: 1rem;

            @media (max-width: 1199px) {
                max-width: 42rem;
            }

            .modal-content {
                border: 2px solid $baseclr2;
                background-color: $black23;
                box-shadow: 0px 0px 40px 0px rgba($baseclr2, 0.302);
                padding: 4rem 0;

                @media (max-width: 1439px) {
                    padding: 2rem 0;
                }
            }

            .modal-header {
                display: flex;
                align-items: center;
                border: none;
                position: relative;
                padding: 2rem;

                h2 {
                    text-align: center;
                    padding-inline: 3.6rem;
                    width: 100%;
                }

                button {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    flex-shrink: 0;
                    padding: 0;
                    width: 3.6rem;
                    margin-left: auto;
                    position: absolute;
                    right: 2rem;
                    height: 3.6rem;
                }
            }

            .modal-body {
                color: $white;
                text-align: center;
                padding: 0 2rem 2rem;
            }
        }
    }

    h2 {
        color: $baseclr;
        font-size: 2rem;
        line-height: 2rem;
    }

    p {
        margin-top: 2rem;
        font-size: 1.8rem;

        @media (max-width: 1439px) {
            font-size: 1.6rem;
        }

        @media (max-width: 1199px) {
            font-size: 1.4rem;
        }
    }
}