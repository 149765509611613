@import "../../../assets/theme/_var.scss";


.error_page,
.not_found {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-image: url($s3bucket + "/login-bg.webp");
    z-index: 1;

    .animation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;

        svg {
            object-fit: cover;
            max-height: 100vh;
        }
    }


    &::after {
        content: "";
        z-index: -2;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url($s3bucket + "/login-bg-overlay.webp");
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }
}