@import "../../../../assets/theme/_var.scss";

.input {
    @media (min-width: 576px) {
        align-items: flex-start;
        display: flex;
    }

    @media (max-width: 575px) {
        text-align: left;
    }

    label {
        white-space: nowrap;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.24rem;
        color: $baseclr;
        transform: translateY(2rem);

        @media (max-width: 1199px) {
            transform: translateY(0.8rem);
            font-size: 1.4rem;
        }

        @media (max-height: 619px) and (max-width: 1439px) {
            font-size: 1.2rem;
        }

        @media (max-width: 575px) {
            margin-bottom: 1rem;
        }

        span {
            &.required {
                left: -1rem;
                position: relative;
                font-size: 2rem;
            }
        }
    }

    .input_inner {
        margin-left: 2rem;

        @media (max-width: 575px) {
            margin-left: 0;
        }

        input,
        textarea {
            color: $white;
            font-family: $secondaryfont;
            width: 100%;
            font-size: 1.6rem;
            padding-inline: 2.1rem;
            font-weight: 400;
            background-color: rgba($black, 0.6);
            border-radius: 0.4rem;
            border: 1px solid transparent;
            outline: none;
            height: 6rem;

            @media (max-width: 991px) {
                height: 5rem;
            }

            @media (max-width: 575px) {
                border: 1px solid rgba($white, 0.12);
            }

            @media (max-height: 619px) and (max-width: 1439px) {
                height: 4.8rem;
                font-size: 1.4rem;
            }

            @media (max-height: 479px) and (max-width: 1439px) {
                height: 4rem;
            }

            @media (max-height: 419px) and (max-width: 1439px) {
                height: 3.6rem;
                font-size: 1.2rem;
            }

            &::placeholder {
                color: rgba($white, 0.5);
            }

            &:focus {
                border: 1px solid rgba($baseclr, 0.6);
                color: $white;
            }
        }

        textarea {
            height: auto;
            resize: none;
            padding-top: 1.4rem;
        }
    }
}